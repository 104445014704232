<template>
  <a-date-picker
    v-bind="record.options"
    :value="momentValue"
    :format="format"
    :open="panelStatus"
    :getCalendarContainer="record.options.getCalendarContainer || (triggerNode => triggerNode.parentNode)"
    mode="year"
    @change="change"
    @openChange="openChange"
    @panelChange="panelChange"
  />
</template>

<script>
import moment from 'moment'
import formEleMixin from '../form-ele-mixin'
import mixin from './mixin'
export default {
  name: 'AxYearPicker',
  mixins: [formEleMixin, mixin],
  data () {
    return {
      panelStatus: false,
      dateFormat: 'YYYY'
    }
  },
  computed: {
    momentValue () {
      return this.value ? moment(this.value, this.format) : undefined
    },
    format () {
      return this.record.options.format || this.dateFormat
    }
  },
  methods: {
    /**
     * 弹出面板和关闭面板的回调
     * @param status
     */
    openChange (status) {
      this.panelStatus = status
      this.$emit('openChange', status, this.record.model)
    },
    /**
     * 日期面板变化时的回调
     * @param momentValue
     */
    panelChange (momentValue) {
      const value = momentValue.format(this.format)
      this.panelStatus = false
      // 这里必须执行change，否则值赋不到输入框，同时组件上的change方法也要保留，否则无法用clear图标删除
      this.$emit('change', value, this.record.model)
      this.$emit('panelChange', value, this.record.model)
    }
  }
}
</script>

<!--
未设置属性：
valueFormat
-->

<!--
注：
yearPicker的disabledDate无效，需手动去控制
-->
